// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import "timeago"
import "trix"
import "@rails/actiontext"
import "jquery.caret"


import jQuery from "jquery"

window.Backone = require("backbone")
window.jQuery = jQuery
window.$ = jQuery

import "./vendor"

import Hls from "hls.js";
import DPlayer from "dplayer";

const AppView = Backone.View.extend({
    el: 'body',
    repliesPerPage: 50,
    windowInActive: true,
    events: {
        "click #mobile-toggle": "toggleMobileMenu",
    },

    toggleMobileMenu() {
        document.getElementById('mobile-menu ').classList.toggle('hidden');
    }
})

var strings = {
    "zh-CN": {
        prefixAgo: null,
        prefixFromNow: null,
        suffixAgo: "前",
        suffixFromNow: "刚刚",
        seconds: "1 分钟",
        minute: "约 1 分钟",
        minutes: "%d 分钟",
        hour: "1 小时",
        hours: "%d 小时",
        day: "1 天",
        days: "%d 天",
        month: "1 月",
        months: "%d 月",
        year: "1 年",
        years: "%d 年",
        numbers: [],
        wordSeparator: ''
    },
    "en": {
        prefixAgo: null,
        prefixFromNow: null,
        suffixAgo: "ago",
        suffixFromNow: "one second",
        seconds: "few seconds",
        minute: "one minute",
        minutes: "%d minutes",
        hour: "one hour",
        hours: "%d hours",
        day: "one day",
        days: "%d days",
        month: "one month",
        months: "%d months",
        year: "one year",
        years: "%d years",
        numbers: []
    },
    "zh-TW": {
        prefixAgo: null,
        prefixFromNow: null,
        suffixAgo: "前",
        suffixFromNow: "剛剛",
        seconds: "1 分鐘",
        minute: "約 1 分鐘",
        minutes: "%d 分鐘",
        hour: "1 小時",
        hours: "%d 小時",
        day: "1 天",
        days: "%d 天",
        month: "1 月",
        months: "%d 月",
        year: "1 年",
        years: "%d 年",
        numbers: [],
        wordSeparator: ''
    }
};

document.addEventListener('turbo:load', () => {
    var url = $('#player-url').val();
    var container = document.getElementById('dplayer-container');
    if (url && container) {
        var dp = new DPlayer({
            container: container,
            video: {
                url: url,
                type: 'customHls',
                customType: {
                    customHls: function (video, player) {
                        const hls = new Hls();
                        hls.loadSource(video.src);
                        hls.attachMedia(video);
                    },
                },
            },
        });
    }
    window._appView = new AppView();
    $('img').on("error", function () {
        $(this).attr('src', '/assets/missing.svg');
    });
});


$("document").ready(function () {
    var localized = strings["zh-CN"];
    if (localized) {
        jQuery.timeago.settings.strings = localized;
        // Display original dates older than 3 months.
        jQuery.timeago.settings.cutoff = 1000 * 60 * 60 * 24 * 30 * 3;
        $("abbr.timeago").timeago();
    }
})
